/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Aktuality"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="--center" style={{"paddingTop":136,"paddingBottom":21}} name={"s6uq1ht91"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--43" content={"<span style=\"text-decoration-line: underline; color: rgb(224, 44, 12);\">AKTUALITY</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":50,"marginBottom":31,"paddingBottom":0}} name={"8e1lcz51585"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" style={{"maxWidth":800,"paddingLeft":0,"paddingRight":0}} columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"paddingTop":0}}>
              
              <Text className="text-box text-box--left fs--18 w--500" style={{"maxWidth":848,"paddingLeft":0,"paddingRight":0}} content={"<span style=\"font-weight: bold; text-decoration-line: underline; color: rgb(213, 71, 6);\">Vodohospodářská laboratoř Říčany přijme IHNED laborantku</span><br><br>Požadujeme:\n<br>- středoškolské vzdělání chemického nebo podobného zaměření\n<br>- řidičský průkaz skupiny B\n<br>- praxe v laboratoři výhodou\n<br>Nabízíme:\n<br>- zajímavá a různorodá práce v menším ženském kolektivu na hlavní pracovní poměr\n<br>- pracovní smlouva na dobu neurčitou (po úspěšném zapracování)\n<br>- jednosměnný provoz s flexibilní pracovní doba\n<br>- týden dovolené navíc\n<br>- pět dní zdravotního volna za rok\n<br>- stravenky\n<br>- bástup je možný ihned<br><br>Kontakt: 602363180, 323608539&nbsp; &nbsp;email: laboratorkopecna@seznam.cz&nbsp;<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"marginTop":26,"paddingTop":0,"paddingLeft":103,"paddingRight":24,"paddingBottom":0,"backgroundColor":"rgba(160, 160, 160, 1)"}} name={"o-nas"}>
          
          <ColumnWrap className="column__flex el--2 mb--10 mt--10" style={{"maxWidth":1300}} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Text className="text-box" style={{"maxWidth":606}} content={"<br><span style=\"color: var(--black); font-weight: bold;\">Vodohospodářská laboratoř Říčany, s. r. o.\n<br>Kolovratská 1476,251 01 Říčany\n<br>tel.: 323602539\nmobil: 602363180\n<br>e-mail: laboratorkopecna@seznam.cz\n<br><br>&nbsp;IČO 28393601\nDIČ CZ28393601</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Text className="text-box text-box--center" style={{"maxWidth":495}} content={"<span style=\"color: var(--black);\"><span style=\"font-weight: bold; text-decoration-line: underline;\">Příjem vzorků vody:\n</span><br>Pondělí a úterý: 8.00 – 15.30 hod.<br>Středa: 8.00 - 11.00 hod.\n<br><br>&nbsp;<span style=\"text-decoration-line: underline; font-weight: bold;\">Pracovní doba laboratoře:\n</span><br>Pondělí až čtvrtek: 8.00 - 15.30 hod. (po dohodě do 18 hod.)<br>Pátek: 8.00 - 14.00 hod.\n<br></span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}